import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, UncontrolledTooltip } from "reactstrap"
import { useHistory } from "react-router"
import { connect } from "react-redux"
import { openWelcome } from "store/actions"

const vidi = {
  position: "relative",
  height: "100%",
  width: "100%",
  overflow: "hidden scroll",
  display: "none",
}

const Dashboard = props => {
  const { welcome, onOpenWelcome } = props

  const history = useHistory()
  function tAdd() {
    const body = document.body
    body.classList.add("vertical-collpsed")
    body.classList.add("sidebar-enable")
  }

  const loader = document.querySelector(".loader")

  useEffect(() => {
    loader.classList.add("loader--hide")
  }, [])
  return (
    <React.Fragment>
      <div style={{ padding: 0 }} className="page-content">
        <MetaTags>
          <title>Teleperformance Immersive Experience</title>
        </MetaTags>

        <Container style={{ padding: 0 }} fluid>
          <img onClick={()=>history.push("/page/lobby")}
            id="img-1"
            alt=""
            src={
              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAR%20login%20%5BRecovered%5D-05-05.png?alt=media"
            }
            width="100%"
            style={{
              top: "0%",
              width: "100%",
              position: "absolute",
              height: "100%",
              left: "0%",
              objectFit:"cover"
            }}
            onLoad={() => {
              tAdd()
            }}
          />
          {/* <div>
            <UncontrolledTooltip target="skip1">Skip</UncontrolledTooltip>
            <div
              id="skip1"
              // onClick={() => { history.push("/page/lobby") }} //addedy
              onClick={() => {
                //change here for redirection after on click on skip
                history.push("/page/lobby")
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "skew(0deg, 0deg)",
                position: "absolute",
                width: "9.38%",
                height: "16.67%",
                top: "0.65%",
                left: "86.41%",
                zIndex: "3",
              }}
            >
             <span className="mdi mdi-skip-next"></span>
            </div>
          </div>
          <div>
            <UncontrolledTooltip target="click2enter">
              Click to Enter
            </UncontrolledTooltip>

            <div
              id="click2enter"
              // onClick={() => { history.push("/page/lobby") }} //addedy
              onClick={() => {
                // onOpenWelcome();
                document.getElementById("vid-1").style.display = "block"
                document.getElementById("img-1").style.display = "none"
                document.getElementById("vid-1").play()
              }}
              style={{ 
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "skew(0deg, 0deg)",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0%",
                left: "0%",
              }}
            ></div>
          </div> */}

          {/* <video
            id="vid-1"
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Demo%2FExt.mp4?alt=media"
            style={vidi}
            autostart="false"
            onLoadStart={() => {
              tAdd()
            }}
            onEnded={() => {
              history.push("/page/lobby")
            }}
          ></video> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

// export default Dashboard

const mapStateToProps = ({ agenda }) => ({
  welcome: agenda.welcome,
})

const mapDispatchToProps = dispatch => ({
  onOpenWelcome: () => dispatch(openWelcome()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
