import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  CardBody,
  Card,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import Logimg from "../../assets/images/Loginpage.jpg"
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// import LobImg from "../../assets/images/lobImg.jpg"
// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"
import classnames from "classnames"
import { isMobile } from "react-device-detect"
import AddToHomeScreenPrompt from "components/AddToHome"
// import firebase from "firebase/app"
// import "firebase/analytics"
// import "firebase/messaging"

const Login = props => {
  const [ms, setMS] = useState(false)

  const [ActiveTab, setActiveTab] = useState("Individual")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const [mssgToken, setmssgToken] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  const togglePassword =()=>{
    setShowPassword(!showPassword);
  }
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }
  const [alert, setAlert] = useState(true)
  const alertToggle = () => {
    setAlert(!alert)
  }
  const hideAlert = () => {
    setAlert(false)
  }
  const loader = document.querySelector(".loader")

  useEffect(() => {
    // const firebaseConfig = {
    //   apiKey: "AIzaSyDhyZp6xSQeZZBvKnPqkE5mwoUTDIiBjA0",
    //   authDomain: "userposts-64965.firebaseapp.com",
    //   projectId: "userposts-64965",
    //   storageBucket: "userposts-64965.appspot.com",
    //   messagingSenderId: "483796190733",
    //   appId: "1:483796190733:web:e29eef974a10885c427744",
    //   measurementId: "G-N8E9G16ELR",
    // }

    // // init firebase backend
    // // initFirebaseBackend(firebaseConfig)

    // firebase.initializeApp(firebaseConfig)
    // const analytics = firebase.analytics()
    // const messaging = firebase.messaging()

    // // Get registration token. Initially this makes a network call, once retrieved
    // // subsequent calls to getToken will return from cache.
    // messaging
    //   .getToken({
    //     vapidKey:
    //       "BJ4DhIruUgaLVh2H8orodqcT0TUQAC7NKuaETeic9b_fnpc33JPb02uVlda_zGaKiwPKVkd4GOlZNCYJ11A_H1g",
    //   })
    //   .then(currentToken => {
    //     if (currentToken) {
    //       console.log(currentToken)
    //       setmssgToken(currentToken);
    //       // Send the token to your server and update the UI if necessary
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       )
    //       // ...
    //     }
    //   })
    //   .catch(err => {
    //     console.log("An error occurred while retrieving token. ", err)
    //     // ...
    //   })

    loader.classList.add("loader--hide")

    setmssgToken(localStorage.getItem("notificationToken"))
  }, [])

  return (
    <React.Fragment>
    <AddToHomeScreenPrompt></AddToHomeScreenPrompt>
      <MetaTags>
        <title>Teleperformance Immersive Experience</title>
      </MetaTags>
      <Modal
        size="xl"
        isOpen={ms}
        toggle={() => {
          setMS(!ms)
        }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Photo Mosaic</h5>
          <button
            type="button"
            onClick={() => {
              setMS(!ms)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            allow="camera; microphone"
            title="test"
            className="embed-responsive-item mosaic"
            src={encodeURI("https://demo-mosaic.herokuapp.com/")}
          />
        </div>
      </Modal>
      <Container style={{ padding: 0, position: "relative" }} fluid>
        {isMobile ? (
          <img
            // src={Logimg}
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FTP%20Pink%20%26%20white%20Login%20page%20baground.jpg?alt=media"
            style={{ position: "absolute", width: "100%" }}
          ></img>
        ) : (
          <img
            // src={Logimg}
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FHolding%20Landscape-06.png?alt=media"
            style={{ position: "absolute", width: "100%" }}
          ></img>
        )}
        <div className="account-pages pt-sm-5">
          <center className="loginCenter">
            <Card
              className="overflow-hidden cardLogin"
              style={{
                backgroundColor: "transparent",
                // boxShadow: "0px -5rem 2rem 0px rgb(18 38 63 / 3%)",
                boxShadow: "none",
                color: "#fff",
              }}
            >
              <CardBody className="pt-0">
                <div className="p-2">
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      {/* <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: ActiveTab === "Individual",
                        })}
                        onClick={() => {
                          toggle1("Individual")
                          setactiveTabContent("1")
                        }}
                      >
                        Login
                      </NavLink> */}
                      <div id="1"></div>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: ActiveTab === "Team",
                        })}
                        onClick={() => {
                          toggle1("Team")
                          setactiveTabContent("2")
                        }}
                      >
                        Register
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  {/* <div style={{fontSize:"0.7rem",textAlign:"left",color:"black",fontWeight:"bold"}} className="mt-1" >I consent and authorize ABB to the use of my data as part of an ABB initiative to celebrate the Technology Day virtually. Please refer to the ABB privacy policy to know more - <a href="https://new.abb.com/privacy-notice/virtual-events">https://new.abb.com/privacy-notice/virtual-events</a></div> */}
                  <TabContent
                    activeTab={activeTabContent}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          props.error == "Invalid Email!" ||
                          props.error ==
                            "Somethiing went wrong, Please try again!" ||
                          props.error ==
                            "User Already registered , Please come back again on the event day to log in." ||
                          props.error ==
                            "Oops! Your password is incorrect. Please try again using the correct password." ||
                          props.error ==
                            "User Already registered , please login" ||
                          props.error == "User not Found!" ||
                          props.error ==
                            "User not Found! Please Register to login" ||
                          props.error ==
                            "Your ID has been registered. Please come back again on the event day to log in." ? (
                            props.error ==
                            "Your ID has been registered. Please come back again on the event day to log in." ? (
                              <SweetAlert
                                show={alert}
                                success
                                // showCancel
                                title="Registered Successfully"
                                cancelBtnBsStyle="danger"
                                // confirmBtnText="Photo Mosaic"
                                onConfirm={() => {
                                  // setMS(true);
                                  hideAlert(false)
                                }}
                                // onCancel={hideAlert}
                                // onClick={console.log("hi")}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            ) : props.error ==
                              "User Already registered , Please come back again on the event day to log in." ? (
                              <SweetAlert
                                show={alert}
                                success
                                // showCancel
                                title="Already Registered"
                                cancelBtnBsStyle="danger"
                                // confirmBtnText="Photo Mosaic"
                                onConfirm={() => {
                                  // setMS(true);
                                  hideAlert(false)
                                }}
                                // onCancel={hideAlert}
                                // timeout={4000}
                              >
                                {props.error} Please click on Photo Mosaic to
                                submit picture for RALLY OF INCLUSION.
                              </SweetAlert>
                            ) : (
                              <SweetAlert
                                show={alert}
                                warning
                                // title="  Failed!"
                                onConfirm={hideAlert}
                                // timeout={4000}
                              >
                                {props.error}
                              </SweetAlert>
                            )
                          ) : (
                            <SweetAlert
                              show={alert}
                              warning
                              title="Failed!"
                              onConfirm={hideAlert}
                              // timeout={4000}
                            >
                              {props.error}
                            </SweetAlert>
                          )
                        ) : null}
                        {/* <div className="newlog">
                      Login
                    </div> */}
                        {/* <div className="mb-3">
                          <AvField
                            name="Firstname"
                            value=""
                            className="form-control newgrad"
                            placeholder="First Name *"
                            type="text"
                            required
                            style={{}}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="Lastname"
                            value=""
                            className="form-control newgrad"
                            placeholder="Last Name *"
                            type="text"
                            required
                          />
                        </div> */}

                        <div className="mb-3">
                          <AvField
                            name="Email"
                            value=""
                            className="form-control newgrad"
                            placeholder="Email ID*"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3" style={{ position: "relative" }}>
                          {showPassword ? (
                            <i
                              className="bx bx-show-alt bx-sm "
                              style={{
                                position: "absolute",
                                right: "7px",
                                top: "5px",
                              }}
                              onClick={togglePassword}
                            ></i>
                          ) : (
                            <i class='bx bx-hide bx-sm'
                              style={{
                                position: "absolute",
                                right: "7px",
                                top: "5px",
                              }}
                              onClick={togglePassword}
                            ></i>
                          )}

                          <AvField
                            name="Password"
                            value=""
                            className="form-control newgrad"
                            placeholder="Password *"
                            type={showPassword ? "text" : "password"}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="Token"
                            value={mssgToken}
                            className="form-control newgrad"
                            placeholder="token"
                            type="text"
                            hidden
                            // required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="customaction"
                            value="Login"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>

                        <div
                          className="mt-3 d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              borderColor: "#EF0084",
                              // width: "33%",
                            }}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                        {/* <div style={{ fontSize: "0.7rem", textAlign: "left", color: "black", fontWeight: "bold" }} className="mt-1">Kindly Register for before login. </div> */}
                      </AvForm>
                    </TabPane>

                    <TabPane tabId="2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          props.error == "Invalid Email!" ||
                          props.error ==
                            "Somethiing went wrong, Please try again!" ||
                          props.error ==
                            "Oops! Your password is incorrect. Please try again using the correct password." ? (
                            <SweetAlert
                              show={alert}
                              warning
                              title="Login Failed!"
                              onConfirm={hideAlert}
                              // timeout={4000}
                            >
                              {props.error}
                            </SweetAlert>
                          ) : (
                            <SweetAlert
                              show={alert}
                              success
                              title="Login Success!"
                              onConfirm={hideAlert}
                              // timeout={4000}
                            >
                              {props.error}
                            </SweetAlert>
                          )
                        ) : null}
                        {/* <div className="newlog">
                      Login
                    </div> */}
                        <div className="mb-3">
                          <AvField
                            name="Firstname"
                            value=""
                            className="form-control newgrad"
                            placeholder="First Name *"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="Lastname"
                            value=""
                            className="form-control newgrad"
                            placeholder="Last Name *"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Email"
                            value=""
                            className="form-control newgrad"
                            placeholder="Email ID*"
                            type="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="customaction"
                            value="Register"
                            className="form-control newgrad"
                            placeholder="action *"
                            type="text"
                            required
                            disabled
                            hidden
                          />
                        </div>
                        {/* <div className="mb-3">
                          <AvField
                            name="Password"
                            value=""
                            className="form-control newgrad"
                            placeholder="Password *"
                            type="password"
                            required
                          />
                        </div> */}
                        <div
                          className="mt-3 d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            style={{
                              backgroundColor: "#414143",
                              color: "white",
                              borderColor: "white",
                              // width: "33%",
                            }}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                        {/* <div style={{ fontSize: "0.7rem", textAlign: "left", color: "black", fontWeight: "bold" }} className="mt-1">Kindly Register for before login. </div> */}
                      </AvForm>
                    </TabPane>
                  </TabContent>
                  *Please use your registered email ID to login
                </div>
              </CardBody>
            </Card>
          </center>
        </div>
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
