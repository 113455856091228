import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const AddToHomeScreenPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }

        setDeferredPrompt(null);
        setIsVisible(false);
      });
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div>
      {isVisible && !isIOS && (
        <div className="add-to-home-screen-prompt">
          <p>Add this app to your home screen for a better experience!</p>
          <button onClick={handleInstall}>Install</button>
          <button onClick={handleClose}>Close</button>
        </div>
      )}

      {isVisible && isIOS && (
        <Modal isOpen={isVisible} toggle={handleClose} style={{position:"relative",top:"50%",left:"48%",transform:"translate(-50%,-50%)"}}>
          <ModalHeader toggle={handleClose}>Add to Home Screen</ModalHeader>
          <ModalBody>
            <video
              title="Video"
              width="100%"
              height="315"
              src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAdd%20to%20Home%20Screen.mp4?alt=media"
              frameBorder="0"
              allowFullScreen
              autoPlay
              muted
              controls={true}
            />
          </ModalBody>
          <ModalFooter style={{justifyContent:"center"}}> 
            <Button color="secondary" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default AddToHomeScreenPrompt;
