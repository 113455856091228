import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
// import {subscribeUser} from "./subscription"
import store from "./store"
import { isMobile } from "react-device-detect"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import { Container } from "reactstrap"
import localJsonData from "./assets/localdata.json"
// subscribeUser()
const loader = document.querySelector(".loader")


// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide")

const hideLoader = () => loader.classList.add("loader--hide")
const allDeviceSupport = false;

const app = (
  <Provider store={store}>
  <BrowserRouter>
    {allDeviceSupport ? (
      <App hideLoader={hideLoader} showLoader={showLoader} />
    ) : isMobile ? (
      <DeviceOrientation lockOrientation={"portrait"}>
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <App hideLoader={hideLoader} showLoader={showLoader} />
        </Orientation>
        <Orientation orientation="landscape" alwaysRender={false}>
          <Container style={{ padding: 0, position: "relative" }} fluid>
            <img
              width="100%"
              height="100%"
              alt="clickable"
              src={
                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/image1%2Fportrait-orientation.png?alt=media"
              }
              style={{
                zIndex: 9999999999999999999,
                position: "fixed",
                display: "block",
                backgroundColor: "white",
              }}
              onLoad={() => {
                loader.classList.add("loader--hide");
              }}
            />
          </Container>
        </Orientation>
      </DeviceOrientation>
    ) : window.location.pathname === "/activityfeed" ? (
      <App hideLoader={hideLoader} showLoader={showLoader} />
    ) : (
      <DeviceOrientation lockOrientation={"landscape"}>
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <Container style={{ padding: 0, position: "relative" }} fluid>
            <img
              width="100%"
              height="100%"
              alt="clickable"
              src={
                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FHolding%20Landscape-06.png?alt=media"
              }
              style={{
                zIndex: 9999999999999999999,
                position: "fixed",
                display: "block",
                backgroundColor: "white",
              }}
              onLoad={() => {
                loader.classList.add("loader--hide");
              }}
            />
          </Container>
        </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
          <Container style={{ padding: 0, position: "relative" }} fluid>
            <img
              width="100%"
              height="100%"
              alt="clickable"
              src={
                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FHolding%20Landscape-06.png?alt=media"
              }
              style={{
                zIndex: 9999999999999999999,
                position: "fixed",
                display: "block",
                backgroundColor: "white",
              }}
              onLoad={() => {
                loader.classList.add("loader--hide");
              }}
            />
          </Container>
        </Orientation>
      </DeviceOrientation>
    )}
  </BrowserRouter>
</Provider>


)

const localJson = JSON.parse(localStorage.getItem("dataJ"));
if (!localJson) {
  console.log("not found");
  localStorage.setItem("dataJ", JSON.stringify(localJsonData));
}

const adminPanel = isMobile ? `${process.env.REACT_APP_ADMIN}` : `${process.env.REACT_APP_ADMIN_LAPTOP}`;

fetch(adminPanel + "/getJD")
  .then(response => response.json())
  .then(data => {
    localStorage.removeItem("dataJ");
    localStorage.setItem("dataJ", JSON.stringify(data));
  });
  ReactDOM.render(app, document.getElementById("root"));



serviceWorker.unregister()

// serviceWorker.register()
