import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "../Attendee/attendee.css"
import { useHistory } from "react-router-dom"

const Attendee = () => {
  const history = useHistory()
  const [isPgload, setisPgload] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [Data, setData] = useState([])
  const loader = document.querySelector(".loader")
  const fetchUsers = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_ADMIN + "/attendeedata")
      const userdata = await res.json()
      // console.log("---",userdata);
      // console.log("U data--", userdata);
      // console.log("p data--", productdata);

      if (!userdata.length) {
        console.log("Data not found")
        return 0
      } else {
        setData(userdata)
        setFilteredData(userdata)
        return
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const handleProfileClick = (Firstname,Email,id,img) => {
    history.push({
      pathname: '/attendeeprofile',
      state: { Firstname, Email,id,img },
    });
  };
  const handleSearch = searchValue => {
    const filtered = Data.filter(item =>
      item.Firstname.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredData(filtered)
  }
  useEffect(() => {
    fetchUsers()
  }, [])

  //cometchat
  // useEffect(() => {
  //   setTimeout(() => {
  //     CometChatWidget.init({
  //       appID: "249282bb2fe1a7b3",
  //       appRegion: "in",
  //       authKey: "af9c96606f3221aab59d29081a2b8dcceaca4f29",
  //     }).then(
  //       response => {
  //         /**
  //          * Create user once initialization is successful
  //          */
  //         // const obj1 = JSON.parse(localStorage.getItem("authUser"))
  //         const UID = obj._id
  //         // const USERNAME = obj.Email
  //         const USERNAME = obj.Firstname ? obj.Firstname : obj.Email;

  //         // obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
  //         CometChatWidget.createOrUpdateUser({
  //           uid: UID,
  //           name: USERNAME,
  //         }).then(user => {
  //           console.log("Initialization completed successfully")
  //           //You can now call login function.
  //           CometChatWidget.login({
  //             uid: UID,
  //           }).then(
  //             response => {
  //               CometChatWidget.launch({
  //                 //widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
  //                 widgetID: "90caae21-779f-4d80-94e0-31388b864f31",
  //                 docked: "true",
  //                 alignment: "right", //left or right
  //                 roundedCorners: "true",
  //                 height: "calc(100% - 180px)",
  //                 width: "400px",
  //                 defaultID: "", //default UID (user) or GUID (group) to show,
  //                 defaultType: "user", //user or group
  //               })
  //             },
  //             error => {
  //               console.log("User login failed with error:", error)
  //               //Check the reason for error and take appropriate action.
  //             }
  //           )
  //         })
  //       },
  //       error => {
  //         console.log("Initialization failed with error:", error)
  //         //Check the reason for error and take appropriate action.
  //       }
  //     )
  //   }, 1000)
  // },[])

  setTimeout(() => {
    loader.classList.add("loader--hide")
    setisPgload(true)
  }, 2000);

  

  const randomColors = (() => {
    const colors = ["#780096", "#FF0082", "#3047B0", "#00AF9B", "#F5D200", "#0087FF", "#00d769", "#e52322"];
    let previousColors = [];
    
    return () => {
      let randomIndex;
      
      do {
        randomIndex = Math.floor(Math.random() * colors.length);
      } while (previousColors.includes(randomIndex));
      
      previousColors.push(randomIndex);
      
      if (previousColors.length >= 5) {
        previousColors.shift(); // Remove the oldest color
      }
      
      return colors[randomIndex];
    };
  })();
  // #792881 purple
  return (
    <React.Fragment>
      <div 
        className="page-content main-div"
        style={{ backgroundColor: "#fff",fontFamily:"SancoaleSoftenedRegular" }}
      >
        <div className="container-fluid">
          <div className="Attendee-wrapper">
            <h2 style={{color:"#e50075"}}>Attendees</h2>
            <div
              style={{
               backgroundColor: "#fff",
                height: "35px",
                overflow: "hidden",
                width: "100%",
                marginBottom: "10px",
                borderRadius: "20px",
                padding: "0px 15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border:"2px solid  #e50075"
              }}
            >
             <i class='bx bx-search-alt bx-sm'></i>
              <input
                type="text"
                id="search"
                placeholder="Search"
                style={{ border: "none", width:"100%",marginLeft: "20px" }}
                onChange={e => handleSearch(e.target.value)}
              />
            </div>

            <div className="Attendee-content">
              <table>
                <tr>
                  <th>Name</th>
                  {/* <th>Name</th>
            <th>Product</th> */}
                </tr>
                {filteredData.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td className="sancolefont" style={{display:"flex",alignItems:"center"}}
                        onClick={() =>
                          handleProfileClick(val?.Firstname, val?.Email, val?._id,val?.ProfileImg)
                        }
                      >
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center", background: randomColors(),color:"#fff",fontWeight:300,height:"35px",width:"35px",borderRadius:"50%",fontSize:"17px"}}>{val.Firstname.charAt(0)}</div>
                        &nbsp;&nbsp;&nbsp;&nbsp;{val.Firstname} 
                      </td>
                      {/* <td>{val.EmployeeName}</td>
                <td>{val.Product}</td> */}
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Attendee


