import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import QRCode from "qrcode"

const Profile = () => {
  const location = useLocation()
  const loader = document.querySelector(".loader")
  const [isPgload, setisPgload] = useState(false)
  const userData = location.state

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [id, setId] = useState("")
  const [profileImg, setProfileImg] = useState("")
  const [qrImageUrl, setQrImageUrl] = useState()
  const [showuploadBtn,setShowUploadBtn] = useState(false)

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  

  // for qr code
  //    const qrCodeResponse = await QRCode.toDataURL(searchParams.get("id"))
  //    setQrImageUrl(qrCodeResponse)

  // const handleImageChange = async (event) => {
  //   try {
  //     const file = event.target.files[0];
  //     if (file) {
  //           const reader = new FileReader();

  //           reader.onloadend = () => {
  //             const base64Url = reader.result;
  //             setProfileImg(base64Url);
  //             const profileData = JSON.parse(localStorage.getItem("profileData")) || {};
  //           profileData.img = base64Url;

  //           localStorage.setItem("profileData", JSON.stringify(profileData));
  //           };

  //           reader.readAsDataURL(file);
  //         }
  //     const res = await fetch(process.env.REACT_APP_ADMIN + "/profileImg")
  //     const userdata = await res.json()
  //     console.log("---",userdata);
  //     // console.log("U data--", userdata);
  //     // console.log("p data--", productdata);

  //     // if (!userdata.length) {
  //     //   console.log("Data not found")
  //     //   return 0
  //     // } else {
  //     //   setData(userdata)
  //     //   setFilteredData(userdata)
  //     //   return
  //     // }
  //   } catch (e) {
  //     console.error(e)
  //     return false
  //   }
  // }

  const handleImageChange = async event => {
    try {
      const file = event.target.files[0]
      if (file) {
        const base64Url = await convertFileToBase64(file)
        setProfileImg(base64Url)
        const profileData =
          JSON.parse(localStorage.getItem("profileData")) || {}
        profileData.img = base64Url

        localStorage.setItem("profileData", JSON.stringify(profileData))

        updateProfileImage(base64Url)
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const updateProfileImage = async base64Url => {
    try {
    if(email=== authUser.Email) {
      const res = await fetch(process.env.REACT_APP_ADMIN + "/profileImg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Email: email,
          Profile: base64Url,
        }),
      })

      if (res.ok) {
        const userdata = await res.json()
        console.log("---", userdata)
        // Update your local state or storage based on the response if needed
      } else {
        alert("Image upload failed")
      }
    }else{
      alert("Not Allowed");
      setProfileImg("")
    }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(async () => {
    if (userData) {
      setName(userData?.Firstname)
      setEmail(userData?.Email)
      setId(userData?.id)
      setProfileImg(userData?.img)
      setShowUploadBtn(userData?.activateuploadBtn)
      localStorage.setItem("profileData", JSON.stringify(userData))
    }
  }, [userData])

  useEffect(async () => {
    const storedData = localStorage.getItem("profileData")

    if (storedData) {
      const parsedData = JSON.parse(storedData)
      setName(parsedData?.Firstname)
      setEmail(parsedData?.Email)
      setId(parsedData?.id)
      setProfileImg(parsedData?.img)
      // const qrCodeResponse = await QRCode.toDataURL(id)
      // setQrImageUrl(qrCodeResponse)
    }
  }, [])
  setTimeout(() => {
    loader.classList.add("loader--hide")
    setisPgload(true)
  }, 2000)

  //cometchat
  //  useEffect(() => {
  //   setTimeout(() => {
  //     CometChatWidget.init({
  //       appID: "249282bb2fe1a7b3",
  //       appRegion: "in",
  //       authKey: "af9c96606f3221aab59d29081a2b8dcceaca4f29",
  //     }).then(
  //       response => {
  //         /**
  //          * Create user once initialization is successful
  //          */
  //         // const obj1 = JSON.parse(localStorage.getItem("authUser"))
  //         const UID = obj._id
  //         // const USERNAME = obj.Email
  //         const USERNAME = obj.Firstname ? obj.Firstname : obj.Email;
  //         // obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
  //         CometChatWidget.createOrUpdateUser({
  //           uid: UID,
  //           name: USERNAME,
  //         }).then(user => {
  //           console.log("Initialization completed successfully")
  //           //You can now call login function.
  //           CometChatWidget.login({
  //             uid: UID,
  //           }).then(
  //             response => {
  //               CometChatWidget.launch({
  //                 //widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
  //                 widgetID: "90caae21-779f-4d80-94e0-31388b864f31",
  //                 docked: "true",
  //                 alignment: "right", //left or right
  //                 roundedCorners: "true",
  //                 height: "calc(100% - 180px)",
  //                 width: "400px",
  //                 defaultID: "", //default UID (user) or GUID (group) to show,
  //                 defaultType: "user", //user or group
  //               })
  //             },
  //             error => {
  //               console.log("User login failed with error:", error)
  //               //Check the reason for error and take appropriate action.
  //             }
  //           )
  //         })
  //       },
  //       error => {
  //         console.log("Initialization failed with error:", error)
  //         //Check the reason for error and take appropriate action.
  //       }
  //     )
  //   }, 1000)
  // },[])
  return (
    <React.Fragment>
      <div
        className="page-content main-div"
        style={{ backgroundColor: "#792881" }}
      >
        <div className="container-fluid">
          <div
            className="card"
            style={{
              borderRadius: "20px",
              padding: "7% 0px",
              minHeight: "80px",
              marginTop: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {/* <div
              className="card-email"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: "#fff",
              }}
            >
              {email}
            </div> */}
            {/* qr code */}
            {/* <div
              className="card-qr"
              style={{ display: "flex", justifyContent: "flex-start",padding:"10px 0" }}
            >
              <img src={qrImageUrl} height="100px" width="100px" />
            </div> */}
            <div
              className="card-profile-img"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px 0",
                marginBottom: "5%",
              }}
            >
              {profileImg ? (
                <img
                  src={profileImg}
                  height="120px"
                  width="120px"
                  alt="Profile"
                />
              ) : (
                <i className="bx bx-user-circle bx-xlg"></i>
              )}
            </div>
            {showuploadBtn &&
             <div
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
             }}
           >
             <input style={{color:"#fff",textTransform:"capitalize"}}
               type="file"
               accept="image/*"
               onChange={handleImageChange}
             />
           </div>
            }
           
          </div>

          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
            }}
          >
            Profile Data
          </h3>
          <div
            className="card"
            style={{
              minHeight: "fit-content",
              padding: "7%",
              borderRadius: "20px",
              width:"100%",
            }}
          >
            <div className="card-details">
              <div style={{ padding: "10px 10px" }}>
                <label htmlFor="fname" style={{color:"#fff"}}>Name</label>
                <br />
                <input
                  style={{ width: "100%" }}
                  type="text"
                  id="fname"
                  name="fname"
                  value={name}
                  readOnly
                />
                <br />
                <br />
                <label htmlFor="Email" style={{color:"#fff"}}>Email</label>
                <br />
                <input
                  type="text"
                  style={{ width: "100%" }}
                  id="Email"
                  name="Email"
                  value={email}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Profile
